.zigzag-section {
    padding: 50px 0;

    .zigzag-wrapper {
        width: 100%;

        .zigzag-box {
            width: 100%;
            padding: 30px 0;
            position: relative;

            .container {
                justify-content: space-between;
                gap: 30px;
                display: flex;
                align-items: center;

                &:nth-child(even) {
                    flex-direction: row-reverse;
                }

                .thumbnail {
                    img {
                        max-width: 100%;
                    }
                }
            }

            &:nth-child(even) {
                background-color: lighten($primary, 50%);
                position: relative;
                padding: 200px 0;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 131px;
                    width: 100%;
                    background: url('../images/zigzag-shape-top.svg') center top no-repeat;
                    background-size: cover;
                    background-position: 0 -30px;
                    z-index: 1;
                }

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 132px;
                    width: 100%;
                    background: url('../images/zigzag-shape-bottom.svg') center bottom no-repeat;
                    background-size: cover;
                    background-position: 0 50px;
                    z-index: 1;
                }

                .container {
                    flex-direction: row-reverse;
                }
            }

            .double-shape-bottom {
                bottom: -150px;
            }

            &.bg-shapes {
                .double-shape-bottom {
                    bottom: 0;
                    right: 50%;
                }

                .double-shape-top {
                    left: 10%;
                }
            }
        }
    }
}

@media(max-width: 1024px) {
    .zigzag-section {
        .zigzag-wrapper {
            .zigzag-box {
                .container {
                    flex-direction: column;
                }

                &:nth-child(even) {
                    padding: 120px 0;

                    &:after {
                        height: 75px;
                    }

                    &:before {
                        height: 75px;
                        background-position: 0 30px;
                    }

                    .container {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .zigzag-section {
        .zigzag-wrapper {
            .zigzag-box {
                .container {
                    flex-direction: column;
                }

                &:nth-child(even) {
                    padding: 75px 0 85px 0;

                    &:after {
                        height: 35px;
                        background-position: center;
                    }

                    &:before {
                        height: 35px;
                        background-position: center;
                    }

                    .container {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
