/* =========================================
   General
========================================= */

/*! `Custom` Bootstrap 4 theme */
//https://themestr.app/theme?start=1&font=Quicksand&palette=11&icons=fontawesome&border-width=1px&spacer=1rem&btn-border-radius=.25rem&enable-gradients=false

@import url(https://fonts.googleapis.com/css?family=Quicksand:200,300,400,700);

$font-family-base: Quicksand, san-sarif;
$headings-font-family: Quicksand, san-sarif;
//$enable-grid-classes: false;
$primary: #c33664;
//$primary: #1d2671;
//$primary: #a4036f;
//$primary:#563d7c;
$secondary: #272770;
//$secondary: #1d2671;
//$secondary: #868e96;

$enable-gradients: false;
$spacer: 1rem;
$border-width: 1px;
$btn-border-radius: 4.25rem;
$btn-border-radius-sm: 4.25rem;
$btn-border-radius-lg: 4.25rem;

//$success: #28a745;
//$danger: #dc3545;
//$info: #17a2b8;
//$warning: #ffc107;
//$light: #f8f9fa;
//$dark: #343434;

@import "~bootstrap/scss/bootstrap";

// components styles
@import './components/header.scss';
//@import './components/footer.scss';
@import './components/heroSection.scss';
@import './components/whatIsSection.scss';
@import './components/admin-page.scss';
@import './components/zigzag.scss';

//global styles

// Heading & paragraph
h1 {
  font-size: 70px;
  line-height: 1.14;
  font-weight: 700;
}

h2 {
  font-size: 55px;
  line-height: 1.45;
  font-weight: 700;
}

h2 span {
  color: $primary;
}

h3 {
  font-size: 45px;
  line-height: 1.4;
  font-weight: 700;
}

h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.33;
}

h6 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.6;
}

body, p {
  font-size: 22px;
  line-height: 2;
  color: #404040;
}

a {
  text-decoration: none;
  transition: 0.3s all;

  &:not([class]):hover {
    text-decoration: underline;
  }
}

ul {
  padding-left: 15px;

  li {
    padding-left: 30px;
    list-style: none;
    position: relative;

    &::after {
      content: "";
      height: 17px;
      width: 17px;
      background-color: $primary;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 13px;
    }
  }
}

//buttons
.btn {
  box-shadow: none;
  font-size: 22px;
  padding: 21px 66px;
  line-height: 30px;
  text-transform: capitalize;
  transition: 0.3s all;

  &:focus {
    box-shadow: none;
  }

  &:active {
    box-shadow: none
  }

  &:hover {
    opacity: 0.7;
  }
}

.btn-white {
  color: #404040;
  background-color: #fff;
}

.btn-primary {
  background: linear-gradient(80deg, $primary, $secondary);
  border: none;
  box-shadow: 0 30px 30px rgba(64, 64, 64, 0.07);
}

.tooltip {
  opacity: 1;
}

//container

.container {
  max-width: 1525px
}


@media(max-width: 1024px) {
  .container {
    max-width: 860px;
  }
}

@media(max-width: 767px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 40px;
  }

  h2 span {
    color: $primary;
  }

  h3 {
    font-size: 38px;
  }

  body, p {
    font-size: 15px;
  }

  ul {
    padding-left: 0px;

    li {
      padding-left: 15px;

      &::after {
        height: 9px;
        width: 9px;
        top: 10px;
      }
    }
  }

  //buttons
  .btn {
    font-size: 16px;
    padding: 18px 40px;
    line-height: 20px;
  }

}

.divider {
  position: absolute;
  bottom: -1px;
  left: 0;
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    vertical-align: inherit;
  }
}

.toasts-container {
  position: fixed;
  top: 90px;
  right: 45px;
  z-index: 8000;
}

.offcanvas-width {
  width: 100% !important;
}

@include media-breakpoint-up(sm) {
  .offcanvas-width {
    width: 75% !important;
  }
}

.report-btn {
  position: absolute;
  top: 20%;
  right: 10%;
}

@include media-breakpoint-up(sm) {
  .report-btn {
    right: 20%;
  }
}

.styled-switch {
  .form-check-input {
    height: 1.3em;
    width: 2.3em;

  }
}

//.settings-input {
//  border: 1px solid ${colors.gray};
//  outline: none;
//  border-radius: 5px;
//  padding: 0 10px;
//  :focus {
//    border: 1px solid ${colors.primary};
//  }
//}

.stripe-connect {
  display: inline-block;
  height: 38px;
  text-decoration: none;
  width: 180px;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;

  &.default {
    background: #635bff;

    &:hover {
      background: #7a73ff;

    }
  }
}

.stripe-connect span {
  color: #ffffff;
  display: block;
  //font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
  //font-size: 15px;
  //font-weight: 400;
  //line-height: 14px;
  //padding: 11px 0px 0px 24px;
  position: relative;
  text-align: left;
}

.stripe-connect.slate {
  background: #0a2540;
}

.stripe-connect.slate:hover {
  background: #425466;
}

.stripe-connect.white {
  background: #ffffff;
}

.stripe-connect.white span {
  color: #0a2540;
}

.stripe-connect.white:hover {
  background: #f6f9fc;
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  background-size: 49.58px;
  content: "";
  height: 20px;
  left: 62%;
  position: absolute;
  top: 4.95%;
  width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}


/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
