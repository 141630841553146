.hero-section {
    min-height: 65vh;
    position: relative;
    padding: 200px 0 150px 0;
    overflow: hidden;
    background: linear-gradient(150.61deg, #BC3665 6.89%, #272770 111.46%);

    .row {
        z-index: 1;
        position: relative;
        gap: 100px;

        & > div {
            width: calc(50% - 50px);

        }

        position: relative;

        .hero-content {
            h1 {
                margin-top: 22px;
                margin-bottom: 0;
                color: #fff;
            }

            h6 {
                margin-top: 16px;
                margin-bottom: 0;
                color: #fff;
            }

            p {
                margin-top: 22px;
                margin-bottom: 0;
                color: #fff;
            }

            .btn {
                margin-top: 28px;
                margin-bottom: 0;
            }
        }

        .social-icons-bg {
            position: absolute;
            right: 100px;
            top: -80px;
            max-height: 100%;
            z-index: -1;
            animation: animate 5s linear infinite;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .hero-img {
            padding-left: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                width: 100%;
                max-width: 545px;
                height: auto;
            }
        }
    }

    .hero-scroll-btn {
        margin-left: auto;
        margin-right: auto;
        height: 69px;
        width: 69px;
        background-color: rgba(255, 255, 255, 0.150);
        background-image: url('../images/hero-down-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50px;
        border: none;
        outline: none;
        position: relative;
        top: 110px;
        transition: 0.3s all;
        cursor: pointer;

        &:hover {
            transform: translateY(-10px);
        }
    }
}

.hero-shape-divider-top {
    position: absolute;
    bottom: 0;
    height: 100px;
    left: 0;
    width: 100vw;
    max-width: 100vw;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: -3px;
    }
}

.adminHero {
    overflow: hidden;
    background: linear-gradient(150.61deg, #BC3665 6.89%, #272770 111.46%);
    position: relative;
    background-size: cover;

    .container {
        overflow: hidden;
        min-height: 375px;
        position: relative;

        .social-icons-bg {
            position: absolute;
            left: 0;
            top: 115px;
            overflow: hidden;
            height: 150px;
            width: 45%;
            animation: animate 5s linear infinite;

            img {
                height: 700px;
                width: 100%;
            }
        }

        .social-icons-bg2 {
            left: auto;
            right: 0;
        }
    }
}

@media(max-width: 1140px) {
    .hero-section {
        .row {
            gap: 20px;

            & > div {
                width: calc(50% - 10px);
            }
        }
    }
}

@media(max-width: 1024px) {
    .hero-section {
        padding-bottom: 100px;

        .row {
            gap: 0;

            & > div {
                width: 100%;
                text-align: center !important;
                padding: 0 15px !important;
            }

            .hero-img {
                margin-bottom: 40px;
                padding: 0;
                justify-content: center;
            }

            .social-icons-bg {
                display: none;
            }
        }

        .hero-scroll-btn {
            top: 58px;
            height: 40px;
            width: 40px;
            background-size: 35%;
        }
    }
    .adminHero {
        .container {
            min-height: 200px;

            .social-icons-bg {
                display: none;
            }
        }
    }
}

@media(max-width: 767px) {
    .hero-shape-divider-top {
        height: 60px;
    }
}

@media(max-width: 467px) {
    .hero-shape-divider-top {
        height: 35px;
    }
}

@keyframes animate {
    0% {
        transform: translateY(-10px)
    }
    50% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(-10px)
    }
}
