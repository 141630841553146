.header {
  min-height: 140px;
  margin-bottom: -140px;
  z-index: 1000;
  position: relative;
  align-items: baseline;

  .nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .navbar-nav {
    margin-left: 80px;

    .nav-link {
      font-size: 20px !important;
      color: #ffffff !important;
      padding: 20px 0;
      position: relative;
      text-align: center;
      margin: auto 22px auto 0;

      //&:not(:last-child) {
      //  margin-right: 22px;
      //}

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        border-radius: 50px;
        height: 6px;
        width: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all;
      }

      &:hover {
        color: #ffffff;

        &:after {
          opacity: 1;
          visibility: visible;
          width: 50%;
        }
      }

      &.active {
        color: #ffffff;

        &:after {
          opacity: 1;
          visibility: visible;
          width: 50%;
        }
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      color: #fff;
      text-decoration: none;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 13px;
      font-size: 22px;
      font-weight: 500;

      &::after {
        content: none;
      }
    }
  }

  .toggle-btn {
    background-color: transparent;
    border: none;
    display: none;
    outline: none;
  }
}

.btn-close {
  margin-left: auto !important;
}

.profile-btn {
  .dp {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #6B2E6B;
    width: 56px;
    margin: auto;

    img {
      height: 52px;
      width: 52px;
      border-radius: 50%;
      border: 3px solid #fff;
    }
  }
}

.mobilenav + .offcanvas {
  height: 100vh;
}

.mobile-nav-body {
  padding-top: 90px;

  .nav-link {
    font-size: 18px;
    border-bottom: 1px solid #8080801f;
  }

  .profile-btn {
    position: absolute;
    background-color: #ffffff83;
    z-index: 2;
    bottom: 0;

    .dp {
      margin-top: 0.25rem !important;
    }

    .dropdown-toggle {
      padding: 0;
      text-decoration: none;
      font-size: 18px;
    }
  }
}

@media(max-width: 1140px) {
  .header {
    .navbar-nav {
      margin-left: 20px;
    }
  }
}

@media(max-width: 1024px) {
  .header {
    .navbar-nav {
      display: none;
    }

    .profile-btn {
      display: none;
    }

    .toggle-btn {
      display: block;
    }
  }
}
