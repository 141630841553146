.whatIsSection {
  padding: 60px 0;
  margin-top: -2px;
  position: relative;
  background-color: #fff;

  .double-shape-top {
    top: 36%;
  }

  .wis-card {
    .icon {
      background-color: #FFF5F9;
      border-radius: 50%;
      padding: 30px;
      height: 110px;
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.66;
    }
  }
}

.double-shape-top {
  display: flex;
  flex-direction: column;
  max-width: 275px;
  max-height: 311px;
  position: absolute;
  top: 0;
  left: 0;
  animation: animate 5s linear infinite;
  z-index: 6;
}

.double-shape-bottom {
  display: flex;
  flex-direction: column;
  max-width: 275px;
  max-height: 311px;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: animate 5s linear infinite;
  z-index: 6;
}

@media(max-width: 1024px) {
  .double-shape-top {
    display: none !important;
  }

  .double-shape-bottom {
    display: none !important;
  }
}
