.adminInfo {
    margin-top: -115px;
    position: relative;
    z-index: 10;

    .dp {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #6B2E6B;

        img {
            height: 230px;
            width: 230px;
            border-radius: 50%;
            border: 7px solid #fff;
        }
    }

    .name {
        margin-top: 15px;
        margin-bottom: 0;

        &.eighteenplus {
            &::after {
                content: url('../images/18+.svg');
                height: 100%;
                display: inline-block;
                width: auto;
                vertical-align: middle;
                margin-left: 5px;
            }
        }
    }

    .email {
        color: $primary;
        font-weight: 500;
    }

    .social-icons {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0 !important;

        li {
            padding: 0 !important;

            &::after {
                content: none;
            }

            a {
                color: #fff;
                background-color: $primary;
                padding: 5px;
                border-radius: 50%;
                height: 43px;
                width: 43px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;
                transition: 0.3s all;

                &:hover {
                    background-color: lighten($primary, 10%);
                }
            }
        }
    }

    .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        button {
            border: 1px solid #FFD6E4;
            background-color: #FDFAFB;
            border-radius: 10px;
            padding: 4px 20px;
            color: #343434;
            margin: 5px;
            font-weight: 500;

            &:hover {
                text-decoration: none;
                background-color: lighten($primary, 50%);
            }

            &.disabled {
                opacity: 0.6;
            }

            &.seeMore {
                color: $primary;
            }
        }
    }

    .bio {
        max-width: 1100px;
        margin-top: 0px;
    }

    .contact-btns {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.support-section {
    position: relative;
    padding: 50px 0;

    .container {
        max-width: 1000px;

        p {
            margin-top: 17px;
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 0;
        }

        .btn {
            margin-top: 33px;
            width: 100%;
            max-width: 438px;
        }

        .support-wrapper {
            display: flex;
            flex-direction: column;
            max-width: 807px;
            width: 100%;
            margin-top: 70px;

            .support-card {
                display: flex;
                align-items: center;
                border: 2px solid #EAEAEA;
                border-radius: 10px;
                padding: 17px 30px;
                text-align: left;
                gap: 20px;
                width: 100%;

                & > img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                }

                .info {
                    max-width: 495px;
                    margin-right: auto;

                    .name {
                        margin: 0;
                        line-height: 25px;
                        font-weight: 700;

                        span.date {
                            font-size: 16px;
                            color: #BABABA;
                            font-weight: 400;
                        }
                    }

                    .bio {
                        font-size: 16px;
                        line-height: 25px;
                        margin-top: 4px;
                    }
                }

                .price {
                    color: $primary;
                    font-weight: 700;
                    font-size: 35px;
                    white-space: nowrap;

                    &::before {
                        content: "€";
                        display: inline-block;
                        margin-right: 10px;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        .seeMore {
            font-weight: 700;
            font-size: 30px;
            text-transform: capitalize;
            margin-top: 20px;
        }
    }
}

.adminStore {
    background-color: lighten($primary, 50%);
    padding: 50px 0;

    .container {
        max-width: 1525px;

        p {
            max-width: 1000px;
            margin-bottom: 0;
        }

        .store-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .store-card {
                width: calc(25% - 30px);
                margin: 15px;
                text-align: left;
                border: 2px solid #EAEAEA;
                border-radius: 10px;
                overflow: hidden;

                .thumbnail {
                    position: relative;
                    padding-bottom: 75%;
                    background-color: gray;

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                    }
                }

                .info {
                    padding: 13px 23px;

                    .top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .reviews {
                            font-weight: 800;
                            display: flex;
                            align-items: center;

                            .star {
                                color: $primary;
                                font-size: 28px;
                                margin-right: 5px;
                            }

                            .count {
                                font-weight: 500;
                                color: #C3C3C3;
                                font-size: 15px;
                                margin-left: 3px;
                            }
                        }

                        .price {
                            color: $primary;
                            font-weight: 700;
                            font-size: 27px;

                            &::before {
                                content: "€";
                                display: inline-block;
                                margin-right: 10px;
                            }
                        }
                    }

                    .title {
                        font-size: 22px;
                        line-height: 1.3;
                        font-weight: 700;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }

        .see-more {
            margin-top: 30px;
        }
    }
}

.cta-section {
    padding: 40px 0;
    position: relative;

    .container {
        max-width: 1000px;

        .btns {
            margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .btn {
                margin: 15px;
            }
        }
    }
}

@media(max-width: 1200px) {
    .adminStore {
        .container {
            .store-wrapper {
                .store-card {
                    width: calc(33.33% - 30px);
                }
            }
        }
    }
}

@media(max-width: 900px) {
    .adminStore {
        .container {
            .store-wrapper {
                .store-card {
                    width: calc(50% - 30px);
                }
            }
        }
    }
}

@media(max-width: 600px) {
    .support-section {
        .container {
            .support-wrapper {
                .support-card {
                    padding: 10px;
                    gap: 10px;

                    & > img {
                        width: 50px;
                        height: 50px;
                    }

                    .info {
                        .name {
                            line-height: 20px;
                            font-size: 16px;

                            span.date {
                                font-size: 13px;
                            }
                        }

                        .bio {
                            font-size: 12px;
                            line-height: 15px;
                        }
                    }

                    .price {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .adminStore {
        .container {
            .store-wrapper {
                .store-card {
                    width: calc(100% - 30px);
                }
            }
        }
    }
}
